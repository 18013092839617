export const zendeskWidgetAppend = () => {
  if (document.getElementById('ze-snippet')) {
    return;
  }
  const script = document.createElement('script');

  script.id = 'ze-snippet';
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=decf2f78-5072-4248-8294-d2aec9deb324';
  script.async = false;

  document.body.appendChild(script);

  return () => {
    try {
      document.body.removeChild(script);
    } catch (e) {}
  };
};

export const zendeskWidgetRemove = () => {
  while (document.getElementById('ze-snippet') !== null) {
    document.getElementById('ze-snippet')?.remove();
  }
  while (document.getElementById('launcher') !== null) {
    document.getElementById('launcher')?.remove();
  }
};

import { useState, useEffect } from 'react';

type ZendeskChatWidgetTypes = {
	hideOnLoad?: boolean;
	showWidget?: boolean;
	showChat?: boolean;
	onFinishScriptLoad?: () => void;
	onCloseChatWidget?: () => void;
};

const ZendeskChatWidget = (props: ZendeskChatWidgetTypes) => {
	const {
		hideOnLoad = false,
		showWidget = false,
		showChat = false,
		onFinishScriptLoad,
		onCloseChatWidget,
	} = props;
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (document.getElementById('ze-snippet') !== null) {
			setLoaded(true);
			onFinishScriptLoad && onFinishScriptLoad();
		}

		if (document.getElementById('ze-snippet') === null && !loaded) {
			const script = document.createElement('script');
			script.id = 'ze-snippet';
			script.src = `https://static.zdassets.com/ekr/snippet.js?key=decf2f78-5072-4248-8294-d2aec9deb324`;
			script.async = true;
			document.body.appendChild(script);

			const scriptLoadedPromise = new Promise((resolve, reject) => {
				script.onload = resolve;
				script.onerror = reject;
			});

			scriptLoadedPromise.then(() => {
				onFinishScriptLoad && onFinishScriptLoad();
				if (hideOnLoad) {
					// @ts-ignore
					zE('webWidget', 'hide');
				}
			});

			setLoaded(true);
		}
	}, []);

	useEffect(() => {
		// @ts-ignore
		if (loaded && typeof zE === 'function') {
			// @ts-ignore
			zE('webWidget:on', 'close', function () {
				// @ts-ignore
				zE('webWidget', 'hide');
				onCloseChatWidget && onCloseChatWidget();
			});
		}
	}, [loaded]);

	useEffect(() => {
		if (loaded) {
			// @ts-ignore
			showWidget ? zE('webWidget', 'show') : zE('webWidget', 'hide');
		}
	}, [showWidget]);

	useEffect(() => {
		if (loaded) {
			if (showChat) {
				// @ts-ignore
				zE('webWidget', 'show');
				// @ts-ignore
				zE('webWidget', 'open');
			} else {
				// @ts-ignore
				zE('webWidget', 'hide');
			}
		}
	}, [showChat]);

	return null;
};

export default ZendeskChatWidget;

import { useEffect, useMemo, useState } from 'react';
import Tab from '../components/Tab';
import { useAppSelector, useAppDispatch } from '../states/hooks';
import { closeDashboardManualNotification } from '../states/userSlice';
import TopBar from '../components/TopBar';
import { CLIENT_LOCALE } from '../constants/constants';
import { Selector } from 'components/Selector';
import {
	fetchGetAuthenticated,
	fetchPostAuthenticated,
} from 'services/auth.service';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LazyloadImg from 'components/LazyLoadingImg';
import { ASSETS_BASE_URL } from 'config';
import { ClientContainer } from 'packages/unified-dashboard/unified-dashboard.provider';

export type RoleResponseType = {
	id?: number;
	key: string;
	label: string;
	dashboardTitle: string;
};

const containsText = (text: string, searchText: string) =>
	text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const Home = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { userAuth, hasNotViewDashboardManualNotification } = useAppSelector(
		(state) => state.user,
	);
	const [openModal, setOpenModal] = useState(
		hasNotViewDashboardManualNotification,
	);
	const [roles, setRoles] = useState<Array<RoleResponseType>>([]);
	const [selectedRole, setSelectedRole] = useState<RoleResponseType>({
		id: undefined,
		label: '',
		key: '',
		dashboardTitle: '',
	});

	const [searchText, setSearchText] = useState('');
	const displayedRoleOptions = useMemo(
		() => roles.filter((role) => containsText(role.label, searchText)),
		[searchText, roles],
	);

	useEffect(() => {
		const fetchRoles = async () => {
			const res = await fetchGetAuthenticated({
				route: '/v1/dashboard/roles',
				token: userAuth,
				dispatch,
			});
			const mappedRoles: RoleResponseType[] = res.data.roles.map(
				(role: RoleResponseType) => ({
					id: role.id || -1,
					key: role?.key,
					label: role?.label,
					dashboardTitle: role?.dashboardTitle || '',
				}),
			);
			setRoles(mappedRoles);
			setSelectedRole(mappedRoles[0]);
		};
		fetchRoles();
	}, [userAuth]);

	const handleClose = async (
		event: any,
		reason?: 'backdropClick' | 'escapeKeyDown',
	) => {
		if (reason && reason === 'backdropClick') return;

		// no need await we are doing optimistic update
		fetchPostAuthenticated({
			route: '/v1/dashboard/close-guide-notification',
			token: userAuth,
			dispatch,
		});
		dispatch(closeDashboardManualNotification());
		setOpenModal(false);
	};

	const generateModal = () => {
		return (
			<div>
				<Dialog onClose={handleClose} open={openModal} disableEscapeKeyDown>
					<Box
						sx={{ display: 'flex', justifyContent: 'space-between', p: '20px' }}
					>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography
								fontWeight={500}
								fontSize={'16px'}
								color={'#4A5568'}
								m={0}
								p={0}
							>
								{CLIENT_LOCALE === 'id'
									? 'Petunjuk Penggunaan Terbaru Telah Hadir!'
									: 'New User Manual is Here'}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<IconButton
								onClick={handleClose}
								sx={{
									p: 0,
									color: '#4A5568',
								}}
							>
								<Close />
							</IconButton>
						</Box>
					</Box>
					<DialogContent sx={{ px: '20px', py: 0 }}>
						<Stack gap={'24px'}>
							<LazyloadImg
								src={`${ASSETS_BASE_URL}/tutorial/dashboard-manual/reminder_dashboard_manual_guidebook.png`}
								alt="reminder-dashboard-manual"
							/>
							<Typography
								gutterBottom
								fontWeight={400}
								fontSize={'12px'}
								color={'#4A5568'}
								marginBottom={0}
							>
								{CLIENT_LOCALE === 'id'
									? 'Petunjuk Penggunaan dapat membantu anda memahami bagaimana cara menggunakan Management Dashboard ini. Download sekarang di halaman Akun Saya.'
									: 'The user guide can help you understand how to operate the Management Dashboard. Download it now on My Account page.'}
							</Typography>
						</Stack>
					</DialogContent>
					<DialogActions sx={{ p: '20px' }}>
						<Button onClick={handleClose} variant="outlined">
							{CLIENT_LOCALE === 'id' ? 'Nanti Saja' : 'Later'}
						</Button>
						<Button
							onClick={(e) => {
								handleClose(e);
								navigate('my-account');
							}}
							variant="contained"
						>
							{CLIENT_LOCALE === 'id'
								? 'Ke Halaman Akun Saya'
								: 'Go To My Account'}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};

	return (
		<ClientContainer>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					height: '100%',
					overflowY: 'scroll',
				}}
			>
				<TopBar>
					{selectedRole.id ? (
						<Selector
							displayedOptions={displayedRoleOptions}
							selectedOption={selectedRole}
							onChange={(newValue) => {
								const newSelectedRole = roles.find(
									(role) => role.label === newValue,
								);
								if (!newSelectedRole) return;
								setSelectedRole(newSelectedRole);
							}}
							setSearchText={setSearchText}
						/>
					) : null}
				</TopBar>
				<Box display={'flex'} justifyContent={'center'} marginTop={'32px'}>
					<Typography
						color={'primary'}
						sx={{
							fontWeight: 700,
							fontSize: 32,
						}}
					>
						{selectedRole.dashboardTitle}
					</Typography>
				</Box>
				<Box className="self-center" style={{ marginBottom: '80px' }}>
					<Tab
						roleData={{
							roleId: selectedRole.id ?? -1,
							roleType: selectedRole.key,
						}}
					/>
				</Box>
				{generateModal()}
			</Box>
		</ClientContainer>
	);
};

export default Home;

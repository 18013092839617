import { ASSETS_BASE_URL } from 'config';
import LazyloadImg from './LazyLoadingImg';

const BotBar = () => {
  return (
    <div className='flex flex-row justify-center items-start w-full sticky bottom-0 bg-white py-2 gap-2'>
      <LazyloadImg
        src={`${ASSETS_BASE_URL}/ui/powered_by_tvsd.png`}
        alt={'logo'}
        style={{ height: '26px' }}
      ></LazyloadImg>
    </div>
  );
};

export default BotBar;

import TopBar from '../components/TopBar';
import { Box, Button, Grid, Typography } from '@mui/material';
import LazyloadImg from 'components/LazyLoadingImg';
import {
	CLIENT_LOCALE,
	POWERED_BY_OUR_LOGO_GREY,
	PROFILE_PHOTO,
} from 'constants/constants';
import {
	ChevronRight,
	ArrowBack,
	MenuBook,
	Groups,
	PrivacyTip,
	Security,
	Info,
} from '@mui/icons-material';
import Stars from '@mui/icons-material/Stars';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { useNavigate } from 'react-router-dom';
import { fetchGetAuthenticated } from 'services/auth.service';
import { UserManualType } from 'types/legal';
import { enqueueSnackbar } from 'notistack';
import { ClientContainer } from 'packages/unified-dashboard/unified-dashboard.provider';
import { DocumentService } from 'services/document.service';

const typographyStyles = {
	fontSize: '12px',
	fontWeight: 500,
	color: '#718096',
};

enum POLICY_DOCUMENT_IDENTIFIER {
	EULA = 'DASHBOARD_EULA',
	PRIVACY_POLICY = 'DASHBOARD_PRIVACY_POLICY',
	MOBILE_NOTICE = 'DASHBOARD_NOTICE',
	TERMS_OF_USE = 'DASHBOARD_TERMS_OF_USE',
	COOKIE_POLICY = 'DASHBOARD_COOKIE_POLICY',
}

const MyAccount = () => {
	const navigate = useNavigate();
	const userAuth = useAppSelector((state) => state.user.userAuth);
	const dispatch = useAppDispatch();
	const { email, name } = useAppSelector((state) => state.user);

	const handleViewDashboardGuide: React.MouseEventHandler<
		HTMLButtonElement
	> = () => {
		DocumentService.getDashboardManual(userAuth);
	};

	const handleViewLegalPolicy = async (
		policyIdentifier: POLICY_DOCUMENT_IDENTIFIER,
	) => {
		const res = await fetchGetAuthenticated({
			route: '/v1/dashboard/policy-document',
			token: userAuth,
			dispatch,
		});
		if (!res?.data) {
			console.log('Error showing policy document.');
		} else {
			const data = res.data as Array<UserManualType>;
			const docsData = data.find(
				({ policyDocument }) => policyDocument.identifier === policyIdentifier,
			);
			const dashboardFileUrl = docsData?.pdfUrl;
			if (!dashboardFileUrl) {
				enqueueSnackbar('Something went wrong. Please contact admin.', {
					variant: 'info',
				});
				return;
			}
			window.open(dashboardFileUrl);
		}
	};

	const generateOthersList = ({
		iconNode,
		text,
		onClick,
	}: {
		iconNode: JSX.Element;
		text: string;
		onClick: React.MouseEventHandler<HTMLButtonElement>;
	}) => {
		return (
			<Button
				onClick={onClick}
				sx={{
					textTransform: 'none',
					display: 'flex',
					borderTop: 1,
					borderColor: '#E2E8F0',
					padding: '20px',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
					{iconNode}
					<Typography sx={typographyStyles}>{text}</Typography>
				</Box>
				<Box sx={{ display: 'flex' }}>
					<ChevronRight
						sx={{
							height: '24px',
							width: '24px',
						}}
					/>
				</Box>
			</Button>
		);
	};

	return (
		<ClientContainer>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					height: '100%',
				}}
			>
				<TopBar></TopBar>

				<Box className="flex justify-center items-center grow">
					<Box
						className="flex flex-col h-full w-full gap-6"
						sx={{
							minWidth: '400px',
							marginX: '420px',
							paddingY: '48px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								gap: '16px',
								alignItems: 'center',
							}}
						>
							<Button
								onClick={() => navigate(-1)}
								sx={{
									minWidth: '32px',
									padding: '8px',
									background: 'white',
									borderRadius: '4px',
									boxShadow: '0px 0px 10px 0px #2B388F1A',
								}}
							>
								<ArrowBack
									sx={{
										fontSize: '24px',
										// height: '24px',
										// width: '24px',
									}}
								/>
							</Button>
							<Box>
								<Typography
									color={'primary'}
									fontSize={'24px'}
									fontWeight={500}
								>
									{CLIENT_LOCALE === 'id' ? 'Akun Saya' : 'My Account'}
								</Typography>
							</Box>
						</Box>

						{/* first box */}
						<Box
							className="flex flex-col gap-6 p-5"
							sx={{
								borderRadius: '4px',
								boxShadow: '0px 0px 10px 0px #2B388F1A',
							}}
						>
							<Box className="flex">
								<LazyloadImg
									src={PROFILE_PHOTO}
									alt={'Profile Picture'}
									style={{ height: '42px', width: '42px' }}
								/>
							</Box>
							<Box className="flex">
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid item xs={3}>
										<Typography sx={typographyStyles}>
											{' '}
											{CLIENT_LOCALE === 'id' ? 'Nama' : 'Name'}
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography sx={{ ...typographyStyles, fontWeight: 400 }}>
											{name || 'N/A'}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography sx={typographyStyles}>Email</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography sx={{ ...typographyStyles, fontWeight: 400 }}>
											{email}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Box>

						{/* second box */}
						<Box
							className="flex flex-col gap-6"
							sx={{
								borderRadius: '4px',
								boxShadow: '0px 0px 10px 0px #2B388F1A',
							}}
						>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Box sx={{ display: 'flex', padding: '20px' }}>
									<Typography sx={typographyStyles}>
										{CLIENT_LOCALE === 'id' ? 'Lainnya' : 'Others'}
									</Typography>
								</Box>
								{generateOthersList({
									iconNode: (
										<MenuBook
											sx={{
												height: '20px',
												width: '20px',
											}}
										/>
									),
									text:
										CLIENT_LOCALE === 'id'
											? 'Lihat Petunjuk Penggunaan'
											: 'View User Manual',
									onClick: handleViewDashboardGuide,
								})}
								{generateOthersList({
									iconNode: (
										<Groups
											sx={{
												height: '20px',
												width: '20px',
											}}
										/>
									),
									text:
										CLIENT_LOCALE === 'id'
											? 'Lihat Perjanjian Lisensi Pengguna Akhir'
											: 'View End User License Agreement (EULA)',
									onClick: () => {
										handleViewLegalPolicy(POLICY_DOCUMENT_IDENTIFIER.EULA);
									},
								})}
								{generateOthersList({
									iconNode: (
										<PrivacyTip
											sx={{
												height: '20px',
												width: '20px',
											}}
										/>
									),
									text:
										CLIENT_LOCALE === 'id'
											? 'Lihat Perjanjian Privasi Aplikasi Seluler'
											: 'View Mobile Notice',
									onClick: () => {
										handleViewLegalPolicy(
											POLICY_DOCUMENT_IDENTIFIER.MOBILE_NOTICE,
										);
									},
								})}
								{generateOthersList({
									iconNode: (
										<Security
											sx={{
												height: '20px',
												width: '20px',
											}}
										/>
									),
									text:
										CLIENT_LOCALE === 'id'
											? 'Lihat Kebijakan Privasi'
											: 'View Privacy Policy',
									onClick: () => {
										handleViewLegalPolicy(
											POLICY_DOCUMENT_IDENTIFIER.PRIVACY_POLICY,
										);
									},
								})}
								{generateOthersList({
									iconNode: (
										<Info
											sx={{
												height: '20px',
												width: '20px',
											}}
										/>
									),
									text:
										CLIENT_LOCALE === 'id'
											? 'Lihat Ketentuan Penggunaan'
											: 'View Terms of Use',
									onClick: () => {
										handleViewLegalPolicy(
											POLICY_DOCUMENT_IDENTIFIER.TERMS_OF_USE,
										);
									},
								})}
								{generateOthersList({
									iconNode: (
										<Stars
											sx={{
												height: '20px',
												width: '20px',
											}}
										/>
									),
									text:
										CLIENT_LOCALE === 'id'
											? 'Lihat Kebijakan Cookie'
											: 'View Cookies Policy',
									onClick: () => {
										handleViewLegalPolicy(
											POLICY_DOCUMENT_IDENTIFIER.COOKIE_POLICY,
										);
									},
								})}
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className="flex justify-center items-center py-2 border">
					<LazyloadImg
						src={POWERED_BY_OUR_LOGO_GREY}
						alt={'TVSD Logo'}
						style={{ height: '20px' }}
					/>
				</Box>
			</Box>
		</ClientContainer>
	);
};

export default MyAccount;
